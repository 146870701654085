import { defineStore } from 'pinia';
import { THGUserTableColumnVisibilityStatus } from '@/interfaces/thg/THGUserTableColumnVisibility';
import { THGUserTableColumn } from '@/enums/thg/THGUserTableColumn';
import { ref } from 'vue';
import { THGUserFilterParam } from '@/interfaces/thg/THGUserFilterParam';
import { SortOrder } from '@/enums/misc';
import { SortData } from '@/interfaces/misc';

export const useTHGUsersStore = defineStore(
    'thguser',
    () => {
        const filterParam = ref<THGUserFilterParam>({
            is_verified: true,
            is_active: true,
            email: undefined,
            name: undefined,
            phone_number: undefined,
            type: undefined,
            vat_number: undefined,
            is_invalid_vat_number: undefined,
            zip_code: undefined,
            city: undefined,
            street: undefined,
            min_donation_ratio: undefined,
            max_donation_ratio: undefined,
            min_cars_count: undefined,
            max_cars_count: undefined,
            car_year: undefined,
        });

        const columnVisibility = ref<THGUserTableColumnVisibilityStatus>({
            [THGUserTableColumn.VAT_NUMBER]: false,
            [THGUserTableColumn.COMPANY_NAME]: false,
            [THGUserTableColumn.FIRST_NAME]: true,
            [THGUserTableColumn.LAST_NAME]: true,
            [THGUserTableColumn.STREET]: false,
            [THGUserTableColumn.ZIP_CODE]: true,
            [THGUserTableColumn.CITY]: true,
            [THGUserTableColumn.PAYEE]: false,
            [THGUserTableColumn.IBAN]: false,
            [THGUserTableColumn.REGISTRATION_LIMIT]: true,
            [THGUserTableColumn.PROMO_NUMBER]: false,
            [THGUserTableColumn.PROMO_STATUS]: false,
            [THGUserTableColumn.ADAC_MEMBER_NUMBER]: false,
        });

        const sortData = ref<SortData>({ field: 'created_at', order: SortOrder.DESCENDING });

        return {
            filterParam,
            columnVisibility,
            sortData,
        };
    },
    {
        persist: [
            {
                storage: sessionStorage,
                paths: ['columnVisibility'],
            },
        ],
    },
);
