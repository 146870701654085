
    import { Component, Vue, Watch } from 'vue-property-decorator';
    import { InstadrivePhoneNumber } from '@/enums/voice/InstadrivePhoneNumber';
    import voice from '@/store/voice/voice';
    import { mdiFaceAgent, mdiHistory } from '@mdi/js';
    import { Voice } from '@/enums/user/groups/voice';
    import SvgIcon from '@/components/SvgIcon.vue';
    import { SvgIconPath } from '@/utils/SvgIconPath';
    import { EventBus } from '@/main';
    import { GlobalEvent } from '@/enums/GlobalEvent';
    import { CallFilter } from '@/enums/voice/CallFilter';
    import { ecarsoPhoneNumber } from '@/enums/voice/ecarsoPhoneNumber';

    @Component({
        computed: {
            CallFilter() {
                return CallFilter;
            },
        },
        components: {
            SvgIcon,
        },
    })
    export default class Header extends Vue {
        private instadrivePhoneNumber: unknown;
        private ecarsoPhoneNumber: unknown;
        private callGroup: unknown;
        private minDate: Date | null = null;
        private maxDate: Date | null = null;

        private voiceLogo = mdiFaceAgent;
        private historyLogo = mdiHistory;
        private svgicon = SvgIconPath;
        private title = document.title;

        async refreshData() {
            if (this.pn && this.chosenDateFrom) {
                EventBus.$emit(GlobalEvent.START_LOADING);
                await voice.dispatchRetrieveCalls();
                EventBus.$emit(GlobalEvent.STOP_LOADING);
            }
        }

        @Watch('chosenDateFrom')
        onDateFromChanged() {
            this.refreshData();
        }

        @Watch('chosenDateTo')
        onDateToChanged() {
            this.refreshData();
        }

        @Watch('callFilter')
        onCallFilterChanged() {
            this.refreshData();
        }

        @Watch('pn')
        async onPNChanged() {
            if (this.pn && this.chosenDateFrom) {
                EventBus.$emit(GlobalEvent.START_LOADING);

                // compare current pn with pn's in available sip account list.
                const current_acc = voice.state.availableSipAccounts.find(
                    (acc) => acc.callerId === this.pn?.valueOf(),
                );

                if (current_acc) {
                    // set the current sip account (if a personal acc is selected)
                    voice.commitCurrentSipAccount({ currentSipAccount: current_acc });
                } else {
                    voice.commitCurrentSipAccount({ currentSipAccount: null });
                }

                await voice.dispatchRetrieveCalls();

                EventBus.$emit(GlobalEvent.STOP_LOADING);
            }
        }

        get sipAccounts() {
            return voice.state.availableSipAccounts;
        }

        get chosenDateFrom() {
            return voice.state.chosenDateFrom;
        }

        set chosenDateFrom(chosenDateFrom) {
            voice.commitChosenDateFrom({ chosenDateFrom });
        }

        get chosenDateTo() {
            return voice.state.chosenDateTo;
        }

        set chosenDateTo(chosenDateTo) {
            voice.commitChosenDateTo({ chosenDateTo });
        }

        get groupSimilar() {
            return voice.state.groupSimilar;
        }

        set groupSimilar(groupSimilar) {
            voice.commitGroupSimilar({ groupSimilar });
        }

        get callFilter() {
            return voice.state.callFilter;
        }

        set callFilter(callFilter) {
            voice.commitCallFilter({ callFilter });
        }

        get pn() {
            return voice.state.currentPhoneNumber;
        }

        set pn(currentPhoneNumber) {
            voice.commitCurrentPhoneNumber({ currentPhoneNumber });
        }

        mounted() {
            const today = new Date();

            this.minDate = new Date(2020, 11, 1, 0, 0, 0);
            this.maxDate = new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate(),
                23,
                59,
                59,
            );
            this.chosenDateFrom = new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate(),
                0,
                0,
                0,
            );
        }

        beforeCreate() {
            this.instadrivePhoneNumber = InstadrivePhoneNumber;
            this.ecarsoPhoneNumber = ecarsoPhoneNumber;
            this.callGroup = Voice;
        }
    }
